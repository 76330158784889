<template>
  <div class="col-12 col-md-12 col-lg-12 col-xl-12">
    <div class="card-scroll">
      <div class="container d-flex d-sm-flex align-items-sm-center align-self-lg-center ml-1 align-items-lg-center style-product">
        <Product
          v-for="(product, key) in getProducts"
          :key="key"
          :product="product"
          :add-product="addProduct"
          :is-selected="checkProductSelected(product)"
        />
      </div>
    </div>
    <div class="col-12 mt-4  h-100 d-flex align-items-center justify-content-center" >
      <Button 
        tag="Ver Carrinho"         
        type="button" 
        class="btn btn-success col-10 col-sm-4 col-md-4 col-lg-4 mt-3 mb-5" 
        @click="openCart">
        <Icon name="cart"/>
        <span class="ms-1">Ver Carrinho</span>
      </Button>
    </div>  
  </div>
</template>
<script>
import Product from "@/components/Product/Product"
import { mapGetters } from "vuex"
import Button from "@/components/Form/Button/Button.vue";
export default {
  name: "ProductsCatalog",
  components: {
    Product,
    Button    
  },
  props: {
    products: {
      type: Array,
      default: () => ([])
    },
    addProduct: {
      type: Function,
      default: () => {}
    },
    openCart: {
      type: Function,
      default: () => {}
    }
  },
  computed: {
    ...mapGetters({
      hasProductInCartComputed: 'cart/hasProductInCart',
    }),
    getProducts () {
      return !this.products ? [] : this.products
    }
  },
  methods: {
    checkProductSelected (product) {
      return this.hasProductInCartComputed(product?.product)
    }   
  }
}
</script>
<style lang="scss" scoped>
.btn-keep-buying{
  background-color: white;
  color: green;
}
.card-scroll {
  overflow-x: auto;
  white-space: wrap;
  -webkit-overflow-scrolling: touch;
  scrollbar-width: 100%;
  -moz-scrollbar-width: 100%;
  -ms-scrollbar-width: 100%;
}
.style-product {
  @media screen and (min-width: 1024px) {
    justify-content: center;
  }
}
</style>