<template>
  <div :key="accordionKey" class="d-flex h-100 w-100 px-2">
    <div class="card d-flex w-100 h-100">
      <div class="card-header" @click="toggleAccordion()">
        <h5 class="mb-0 d-flex align-items-center justify-content-between">
          <div
            class=""
            data-toggle="collapse"
            :data-target="`#collapse-${accordionKey}`"
            aria-expanded="true"
            :aria-controls="`collapse-${accordionKey}`">
            <slot name="title" />
          </div>
          <Icon
            name="chevron-right"
            class="chevron"
            :class="{ rotate: isOpen }"
          />
        </h5>
      </div>
      <div v-show="isOpen" :id="`collapse-${accordionKey}`" class="accordion-content">
        <slot name="content" />
      </div>
    </div>
  </div>
</template>
<script>
import { defineComponent } from 'vue';
export default defineComponent({
  name: 'AccordionComponent',
  data() {
    return {
      isOpen: false
    };
  },
  props: {
    accordionKey: {
      type: [String, Number],
      required: true
    }
  },
  methods: {
    toggleAccordion() {
      this.isOpen = !this.isOpen;
    }
  }
});
</script>
<style lang="scss" scoped>
.rotate {
  transform: rotate(90deg);
  transition: transform 0.3s ease;
}
.accordion-container {
  width: 80%;
  margin: auto;
}
@media (min-width: 320px) and (max-width: 768px) {  
  .accordion-container {
    width: 100%;
    margin: auto;
  }
}
</style>
